@import '../variable.scss';
@import '../mixins.scss';

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-unselected-hover-icon-color: #{$primary-default-dark !important};
    --mdc-radio-unselected-icon-color: #{$primary-default-dark !important};
    --mdc-radio-unselected-pressed-icon-color: #{$primary-default-dark !important};
    --mdc-radio-selected-focus-icon-color: #{$primary-default-dark !important};
    --mdc-radio-selected-hover-icon-color: #{$primary-default-dark !important};
    --mdc-radio-selected-icon-color: #{$primary-default-dark !important};
    --mdc-radio-selected-pressed-icon-color: #{$primary-default-dark !important};
    --mat-radio-ripple-color: #{$transparent !important};
    --mat-radio-checked-ripple-color: #{$transparent !important};

    .mdc-form-field.mat-internal-form-field{
        .mdc-label{
            @include typography(13px, 400, normal, $Default-Dark);
            @include padding(0, left);
        }
    }
}

.note-type-select-container {
    .mat-mdc-radio-button .mdc-radio {
        @include padding-hor-ver-imp(4px, 4px);
    }
}

.download-file {
    .mdc-radio {
        @include width(12px);
        @include height(12px);
        .mdc-radio__background {
            @include width(16px);
            @include height(16px);
        }
        .mdc-radio__inner-circle {
            @include borderWidth(8px);
        }
    }
    .ibc-body-text-medium {
      @include position(relative);
      @include position-top(1px);
  }
}
mat-radio-group {
    .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:focus:enabled:not(:checked)~.mdc-radio__background .mdc-radio__outer-circle{
        border-color: $primary-default-dark;
    }
}
.mat-mdc-radio-group {
    &.radio-column-layout {
        @include display(grid);
        @include grid-template-column(1fr);
      }
}