@import 'src/styles/variable.scss';
@import 'src/styles/mixins.scss';

.ibc-heading-h1-Large {
  @include typography(44px, 400, normal, $Grey-Charcoal);
}

.ibc-heading-h1 {
  @include typography(34px, 400, normal, $Grey-Charcoal);
}

.ibc-heading-h2-bold {
  @include typography(34px, 700, normal, $Grey-Charcoal);
}

.ibc-heading-h3-bold {
  @include typography(32px, 700, normal, $Grey-Charcoal);
}

.ibc-heading-h4 {
  @include typography(24px, 400, normal, $Grey-Charcoal);
}

.ibc-heading-h4-bold {
  @include typography(24px, 700, normal, $Grey-Charcoal);
}

.ibc-heading-h6-bold-primary {
  @include typography(18px, 700, normal, $primary-default-dark);
}

.ibc-heading-h5 {
  @include typography(20px, 400, normal, $Grey-Charcoal);
}

.ibc-heading-h5-small-bold {
  @include typography(20px, 600, normal, $Grey-Charcoal);
}

.ibc-heading-h6 {
  @include typography(18px, 400, normal, $Grey-Charcoal);
}

.ibc-heading-h6-bold {
  @include typography(18px, 700, normal, $Grey-Charcoal);
}

.ibc-heading-h6-small-bold {
  @include typography(18px, 600, normal, $Grey-Dark);
}

.ibc-heading-h7-subtitle {
  @include typography(16px, 400, normal, $Grey-Charcoal);
}
.ibc-heading-h7-subtitle-bold {
  @include typography(16px, 700, normal, $Grey-Charcoal);
}


.ibc-grid-message-text {
  @include typography(16px, 500, normal, $primary-default);
}

.ibc-heading-h7-bold-subtitle {
  @include typography(16px, 700, normal, $Grey-Dark);
}

.ibc-heading-h7-bold-primary {
  @include typography(16px, 700, normal, $primary-default-dark);
}

.ibc-heading-accordion-title {
  @include typography(15px, 400, normal, $Default-widget-accordion-text);
}

.ibc-grid-message {
  @include typography(15px, 500, normal, $Grey-Dark);
}

.ibc-body-text-large-bold {
  @include typography(14px, 700, normal, $Grey-Charcoal);
}

.ibc-body-text-large {
  @include typography(14px, 600, normal, $Grey-Dark);
}

.ibc-body-text-regular {
  @include typography(14px, 400, normal, $Grey-Dark);
}

.ibc-body-text-medium-bold {
  @include typography(13px, 700, normal, $Grey-Charcoal);
}

.ibc-body-text-medium-bold-table {
  @include typography(13px, 600, normal, $Grey-Charcoal);
}

.ibc-body-text-medium {
  @include typography(13px, 400, normal, $Grey-Slate);
}

.ibc-body-text-small-bold {
  @include typography(12px, 700, normal, $Grey-Slate);
}

.ibc-body-text-small {
  @include typography(12px, 400, normal, $Grey-Charcoal);
}

.ibc-body-table-text {
  @include typography(12px, 400, normal, $Grey-Slate);
}

.ibc-body-table-text-small {
  @include typography(10px, 400, normal, $Grey-Slate);
}

.ibc-body-table-text-small-minute {
  @include typography(8px, 400, normal, $Grey-Slate);
}

.ibc-body-table-text-smallest {
  @include typography(6px, 400, normal, $Grey-Slate);
}

.ibc-body-link-text-small{
  @include typography(12px, 400, normal, $Link);
}

.ibc-body-link-text-medium{
  @include typography(14px, 500, normal, $Link);
}

.ibc-body-link-text-regular{
  @include typography(14px, 400, normal, $Link);
}

.ibc-body-light-small {
  @include typography(10px, 300, normal, $Grey-Dark);
}
.ibc-heading-small-semi-bold-primary {
  @include typography(12px, 600, normal, $primary-default-dark);
}

.ibc-body-text-dark {
  @include typography(14px, 400, normal, $Grey-Charcoal);
}

.ibc-heading-regular-bold-primary {
  @include typography(14px, 700, normal, $primary-default-dark);
}