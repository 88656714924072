//Primary Level
$primary-default-dark: #004480;
$primary-default: #1c7bcf;
$primary-button: #4ea7f5;
$primary-button-default: #036ac6;

//Secondary Level
$Secondary-Default: #7ab1e3;
$Secondary-Default-Dark: #5D8BB3;
$Secondary-Dark: #2ebfb8;
$Secondary-Light: #f1fdfc;
$Secondary-Lighter: #ecf6ff;
$Secondary-Lightest: #b9e2de;
$Secondary-background: #e5e4e4;
$Secondary-Accent-Background: #6399CA;
$Secondary-Accent-Background-Dark: #3E7AB0;

// Default Dark
$Default-Dark: #48465b;
$Default-Light: #737373;
$Default-widget-accordion-text: #7382a3;

//Status Level
$Status-Success: #4caf50;
$Status-Warning: #ffab26;
$Status-Danger: #ff3a46;

// Other Level
$Other-Default: #7b61ff;
$Other-Dark: #b63af0;
$Other-Pale: #0acedb;
$Other-Orange: #fdad2e;
$Other-Blue: #E9F4FF;
$Other-Yellow: #F4D536;

// Grey Scale Level
$Grey-Charcoal: #1e1e1e;
$Grey-Dark: #373737;
$Grey-Slate: #626262;
$Grey-Shadow: #a0a0a0;
$Grey-Cloud: #b6b6b6;
$Grey-Gray: #d2d2d2;
$Grey-Lighter: #dedede;
$Grey-Light: #f5f5f5;
$Grey-Lightest: #fafafa;
$Grey-card-text: #6e8eaa;
$grey-Light-3: #f0f0f0;
$grey-Light-4: #edecec;

// Shadow Level
$shadow-table-header: #fbfbfb;
$Shadow-Marble: #ffffff;

// Critical Level
$Critical-Primary: #f24f7c;
$Critical-Hover: #fe83a5;
$Critical-Pressed: #d3446b;
$Critical-Disabled: #ffabab;
$Critical-Secondary: #ff3a4666;
$Critical-Light: #ffd9d966;

// Checkbox Level
$Checkbox-Disabled-Active: #cadcec;
$Checkbox-Disabled: #f5f5f5;

// Label
$Label: #a0a0a0;
$Table-text: #000000;
$OnSidebarHover: #E6EDF3;
$Icon: #ff365b;
$Table-Pagination-Text: #0000008a;
$Link: #0645ad;
$private-notes: #ffd9d9;

//tootlip
$tooltip-border-color: #ccc;
$transparent: transparent;

// Details Tab
$Details-hawb: #7AB1E31A;
$Disabled-Label-Input: #797979;

//Responsive design
$desktop: 1366px;
$tablet-landscape: 1133px;
$tablet-potrait: 768px;

