@import '../variable.scss';
@import '../mixins.scss';

.ibc-expansion-panel {
  @include borderVal(1px solid $Grey-Lighter);
  @include borderRadius(6px);

  .mat-expansion-panel-header {
    @include borderRadius(6px 6px 0px 0px);
    @include background-Imp($shadow-table-header);
    @include border(bottom, 1px, solid, $Grey-Lighter);
  }

  .mat-expansion-panel-header.mat-expanded {
    @include height-Imp(45px);
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    @include box-shadow(none);
  }

  .mat-expansion-panel-body {
    @include padding-hor-ver(0, 0);
  }

  .mat-expansion-panel-header-title {
    @include flex-basis(unset);
    @include flex-grow(0);
  }

  .active-tab {
    @include color($primary-default-dark);
    @include border(bottom, 2px, solid, $primary-default-dark);
    @include display(inline-block);
  }

  &.custom-permissions-tab-panel {
    .mat-expansion-panel-header {
      @include height(auto !important);
      @include background($shadow-table-header);
      @include border(top, 1px, solid, $Grey-Lighter);
      @include borderRadius(0px);
      >span {
        @include display(grid);
        @include grid-template-column (repeat(auto-fill, minmax(200px, 1fr)));
        @include padding-hor-ver(15px, 0);
      }
    }
  }
}
