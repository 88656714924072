@import 'src/styles/variable.scss';
@import 'src/styles/mixins.scss';

.ibc-tab {
  height: 36px;
  padding: 8px, 16px, 4px, 16px;
  border-radius: 6px, 6px, 0px, 0px;
  color: $Shadow-Marble !important;
  background-color: $primary-default !important;
  border: 1px solid $primary-default !important;
  @include typography(14px, 700, 16.41px, $Grey-Charcoal);
}

.ibc-table-title {
  height: 36px;
  padding: 8px, 16px, 4px, 16px;
  border-radius: 6px, 6px, 0px, 0px;
  color: $Shadow-Marble !important;
  background-color: $primary-default !important;
  border: 1px solid $primary-default !important;
  @include typography(13px, 600, 15.23px, $Grey-Charcoal);
}

.ibc-title-unblocked {
  height: 20px;
  padding: 0px, 10px, 0px, 10px;
  border-radius: 10px;
  color: $Secondary-Lightest !important;
  background-color: $Shadow-Marble !important;
  border: 1px solid $Secondary-Lightest !important;
  @include typography(10px, 400, 20px, $Secondary-Dark);
}

.ibc-title-blocked {
  height: 20px;
  padding: 0px, 10px, 0px, 10px;
  border-radius: 10px;
  color: $Grey-Shadow !important;
  background-color: $Shadow-Marble !important;
  border: 1px solid $Grey-Shadow !important;
  @include typography(10px, 400, 20px, $Grey-Shadow);
}

.ibc-search-bar {
  height: 36px;
  border-radius: 4px;
  color: $Grey-Gray !important;
  background-color: $Shadow-Marble !important;
  border: 1px solid $Grey-Gray !important;
}
