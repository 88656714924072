@import 'src/styles/variable.scss';
@import 'src/styles/mixins.scss';

.field-label {
  @include typography(10px, 400, 11.72px, $Label);
}

.ibc-textbox {
  @include borderRadius(4px);
  @include border('bottom', 1px, solid, $Grey-Gray);
  @include background($Shadow-Marble);

  &:hover {
    @include border('bottom', 1px, solid, $primary-default);
    @include background($Shadow-Marble);
  }

  &:focus {
    @include border('bottom', 1px, solid, $primary-default);
    @include background($Shadow-Marble);
  }

  &:active {
    @include border('bottom', 1px, solid, $primary-default);
  }

  &:in-active {
    @include border('bottom', 1px, solid, $Grey-Gray);
  }

  &:disabled {
    @include border('bottom', 1px, solid, $Grey-Gray);
    @include color($Grey-Gray);
  }
}

.ibc-textbox-small {
  height: 26px;
}

.ibc-textbox-medium {
  height: 36px;
}

.ibc-textbox-large {
  height: 42px;
}
