@import '../mixins.scss';

mat-sidenav {
  @include borderRadiusImp(0px 5px 5px 0px);
  @include padding(40px, top);
}

mat-sidenav-container {
  @include height(100%);
  @include width(auto);
}
