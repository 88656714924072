@import '../mixins.scss';
@import '../variable.scss';

.field-level-permissions-stepper{
    .mat-horizontal-stepper-header-container {
        @include width(50vw);
    }
    .mat-step-icon-selected, .mat-step-icon-state-edit {
        @include background($primary-default-dark);
    }
    .mat-step-icon {
        @include background-color(#ffffff);
        @include border-all(2px, solid, $primary-default-dark);

        &.mat-step-icon-selected {
        @include background($primary-default-dark);
            >div {
                .mat-step-icon-content {
                    @include display(none);
                }
            } 
        }
    }
}