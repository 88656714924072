@import './variable.scss';

.addIcon,
.addComIcon,
.editIcon,
.deleteIcon,
.deleteChildIcon,
.hamburgerRecentListDeleteIcon,
.downloadIcon,
.createDuplicateShipmentIcon {
  stroke: $primary-default;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.viewIcon,
.bagsIcon,
.mawbIcon,
.bagNumbersIcon {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: $primary-default;
}

.editIcon {
  stroke-width: 1.75;
}

.downloadFileIcon,
.printIcon,
.resetIcon,
.saveIcon,
.closeIcon,
.refreshIcon,
.sortIcon,
.dashboardIcon,
.hawbIcon,
.manifestIcon,
.accountPayIcon,
.accountReceiveIcon,
.alertIcon,
.settingIcon,
.notesSaveIcon,
.notesCancelIcon,
.recentSearchIcon,
.pinnedSearchIcon,
.sendCbpClearanceIcon {
  fill: $primary-default;
}

.filterIcon {
  stroke: $primary-default;
  stroke-width: 1.6;
}

.svg-icon:hover {
  .addIcon,
  .addComIcon,
  .editIcon,
  .deleteIcon,
  .deleteChildIcon,
  .filterIcon,
  .downloadIcon,
  .createDuplicateShipmentIcon {
    stroke: $Shadow-Marble;
  }

  .blockShipmentIcon {
    fill-rule: evenodd;
    fill: $Shadow-Marble;
  }

  .viewIcon,
  .downloadFileIcon,
  .printIcon,
  .resetIcon,
  .saveIcon,
  .closeIcon,
  .refreshIcon,
  .advancedSearchTriggerHeaderIcon,
  .advancedSearchPinHeaderIcon,
  .advancedSearchHamburgerHeaderIcon,
  .advancedSearchHelpHeaderIcon,
  .blockShipmentIcon,
  .blockShipmentIcon1,
  .unblockIcon,
  .unblockIcon1,
  .bulkGatewayIcon,
  .createDuplicateShipmentIcon,
  .bulkBagIcon,
  .mailIcon,
  .sendCbpClearanceIcon {
    fill: $Shadow-Marble;
  }

  .dashboardIcon,
  .hawbIcon,
  .bagsIcon,
  .manifestIcon,
  .mawbIcon,
  .accountPayIcon,
  .accountReceiveIcon,
  .alertIcon,
  .settingIcon {
    fill: $primary-default-dark;
  }
}

.activeIcon {
  .dashboardIcon,
  .hawbIcon,
  .bagsIcon,
  .manifestIcon,
  .mawbIcon,
  .accountPayIcon,
  .accountReceiveIcon,
  .alertIcon,
  .settingIcon {
    fill: $Shadow-Marble;
  }
}

.side-nav-button {
  &:hover {
    .dashboardIcon,
    .hawbIcon,
    .bagsIcon,
    .manifestIcon,
    .mawbIcon,
    .accountPayIcon,
    .accountReceiveIcon,
    .alertIcon,
    .settingIcon {
      fill: $primary-default-dark;
    }
  }
}

.svg-icon.icon-bg {
  .addIcon,
  .addComIcon,
  .editIcon,
  .deleteIcon,
  .deleteChildIcon,
  .filterIcon,
  .downloadIcon,
  .createDuplicateShipmentIcon {
    stroke: $Shadow-Marble;
  }

  .viewIcon,
  .downloadFileIcon,
  .printIcon,
  .resetIcon,
  .saveIcon,
  .closeIcon,
  .refreshIcon,
  .dashboardIcon,
  .hawbIcon,
  .bagsIcon,
  .manifestIcon,
  .mawbIcon,
  .accountPayIcon,
  .accountReceiveIcon,
  .alertIcon,
  .settingIcon,
  .createDuplicateShipmentIcon,
  .sendCbpClearanceIcon {
    fill: $Shadow-Marble;
  }
}

.weightIcon,
.piecesPath2,
.hawbNumbersPath2,
.hawbNumbersPath5,
.hawbNumbersPath8,
.bagNumbersIcon,
.locationPinIcon  {
  fill: $Secondary-Dark;
}

.piecesIcon {
  fill: none;

  .piecesPath1 {
    stroke: $Secondary-Dark;
    stroke-width: 2.28571;
  }

  .piecesPath3 {
    stroke: $Secondary-Dark;
    stroke-width: 1.14286;
  }
}

.hawbNumbersIcon {
  fill: none;

  .hawbNumbersPath1,
  .hawbNumbersPath4,
  .hawbNumbersPath7 {
    stroke: $Secondary-Dark;
    stroke-width: 1.30618;
  }

  .hawbNumbersPath3,
  .hawbNumbersPath6,
  .hawbNumbersPath9 {
    fill: $Secondary-Lightest;
    fill-opacity: 0.2;
    stroke: $Secondary-Dark;
    stroke-width: 0.653088;
  }
}

.sidebarArrowIcon,
.sidebarArrowRight,
.closeSnackbarIcon,
.successSnackbarSnackbarIcon .successSnackbarPath1,
.warningSnackbarSnackbarIcon .warningSnackbarPath1,
.errorSnackbarSnackbarIcon .errorSnackbarPath1 {
  fill: $Shadow-Marble;
}

.successSnackbarSnackbarIcon .successSnackbarPath2 {
  fill: $Status-Success;
}

.warningSnackbarSnackbarIcon .warningSnackbarPath2 {
  fill: $Status-Warning;
}

.errorSnackbarSnackbarIcon .errorSnackbarPath2 {
  stroke: $Status-Danger;
  stroke-width: 1.2;
}

.attachment .deleteIcon,
 .attachment .deleteChildIcon, .attachment .downloadIcon {
  fill: $Shadow-Marble;
}

.blockShipmentIcon {
  fill-rule: evenodd;
  fill: $primary-default;
 }

.unblockIcon {
  fill-rule: evenodd;
  fill: $primary-default;
  .unblockIcon1 {
    fill-rule: evenodd;
    fill: $primary-default-dark;
   }
   .unblockIcon2 {
    fill: $Shadow-Marble;
    stroke: $primary-default-dark;
    stroke-width: 2.4;
   }
}

.unblockIcon:hover {
  .unblockIcon2 {
    fill: $Secondary-Default-Dark;
    stroke: $Shadow-Marble;
  }
}

.shipment-block .blockIcon {
   .blockPath1 {
    fill-rule: evenodd;
    fill: $primary-default;
   }
   .blockPath2 {
    fill: $primary-default;
   }
}

.shipment-block.blocked .blockIcon {
  .blockPath1 {
   fill-rule: evenodd;
   fill: $Status-Danger;
  }
  .blockPath2 {
   fill: $Status-Danger;
  }
}

.manifestIcon, .accountPayIcon, .accountReceiveIcon, .alertIcon, .settingIcon {
    @include padding(4px, left);
  }

  .dateIcon {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: $Grey-Cloud;
  }
.infoCircleIcon, .tooltipCloseIcon {
  fill: $Status-Danger;
}

.blueIcon .infoCircleIcon {
  fill: $primary-default;
}

.blueColor {
  .tooltipCloseIcon {
    fill: $primary-default;
  }
}

.notesPublishPath1, .notesPublishPath2 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.3333;
  stroke: red;
}

.notesEditIcon, .notesDeleteIcon {
  fill: none;
}

.notesEditPath1, .notesDeletePath1 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.35912;
  stroke: $primary-default;
}

.notesDeletePath1 {
  stroke-width: 1.4;
}

.notesShortcutPath1 {
  fill: $Grey-Gray;
}

.hawb-summary .infoCircleIcon, .hawb-info-block .infoCircleIcon, .helpTooltipCloseIcon .tooltipCloseIcon {
  fill: $primary-default;
}

.closePopupIcon {
  fill: $Grey-Dark;
}

.warningPopupIcon {
  .warningPath1 {
    fill: $Other-Orange;
  }
  .warningPath2 {
    fill: $Shadow-Marble;
  }
}

.icon-section {
  .addIcon,
  .addComIcon,
  .editIcon,
  .deleteIcon,
  .deleteChildIcon,
  .filterIcon,
  .advancedSearchIcon,
  .createDuplicateShipmentIcon {
    stroke: $primary-default-dark;
  }

  .resetIcon,
  .saveIcon,
  .closeIcon,
  .refreshIcon,
  .viewIcon,
  .downloadFileIcon,
  .printIcon,
  .advancedSearchTriggerHeaderIcon,
  .advancedSearchPinHeaderIcon,
  .advancedSearchHamburgerHeaderIcon,
  .advancedSearchHelpHeaderIcon,
  .blockShipmentIcon,
  .unblockIcon,
  .bulkGatewayIcon,
  .bulkBagIcon,
  .createDuplicateShipmentIcon, .mailIcon, .sendCbpClearanceIcon {
    fill: $primary-default-dark;
  }

  .advancedSearchHamburgerHeaderIcon {
    svg {
      @include position(relative);
      @include position-top(2px);
    }
  }
}

.noNotesPath1 ,.noNotesPath2, .fieldClearIcon {
  fill: $Grey-Gray;
}

.noNotesPath3 {
  fill: $Shadow-Marble;
}

.pinIcon,
.advancedSearchIcon,
.hamburgerIcon,
.saveSearchIcon {
  fill: $primary-default-dark;
}

.saved-search-table-actions, .recent-search-table-actions{
  .searchActionSaveIcon,
  .searchActionPinIcon {
    fill: $primary-default;
  }
}

.saved-search-table-actions {
  .searchActionDeleteIcon,
  .searchActionEditIcon {
    stroke: $primary-default;
    stroke-width: 1.4;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.saved-search-table-actions.disabled {
  .searchActionPinIcon {
    fill:$Grey-Cloud;
  }
  .searchActionDeleteIcon,
  .searchActionEditIcon {
    stroke: $Grey-Cloud;
  }
}

.advancedSearchTriggerHeaderIcon.disabled {
.advancedSearchTriggerHeaderIcon {
    fill: $Shadow-Marble;
    pointer-events: none;
  }
}

.advancedSearchPinHeaderIcon .disabled{
.advancedSearchPinHeaderIcon {
    fill: $Shadow-Marble;
    pointer-events: none;
  }
}

.see-more-icon{
  .notesShortcutPath1 {
    fill: $primary-default-dark;
  }
}

.basicSearchInputIcons{
  .infoCircleIcon{
    fill: $primary-default;
  }
  .searchIcon{
    fill: $primary-default;
  }
}

.basicSearchInputIcons.disabled {
  .searchIcon {
    fill: $Grey-Cloud;
  }
}

.search-option-icons.disabled {
  .pinIcon {
    fill: $Grey-Cloud;
  }
}

.search-modal-action-icons{
  .deleteIcon{
    fill: none;
    stroke: $primary-default-dark;
  }
}

.mawb-widget-icons{
  .manifestNumbersIcon{
    fill: $Secondary-Dark;
    @include position(relative);
    @include  position-top(3px);
    @include  position-left(3px);
  }
  .flightNumbersIcon{
    fill: none;
    stroke: $Secondary-Dark;
    @include position(relative);
    @include  position-top(3px);
    @include  position-left(-1px);
  }
}

.sm-icons{
  .smAgentsIcon, .smServiceTypesIcon, .smServiceTypeGroupsIcon, .smAirlinesIcon, .smAirportsIcon, .smFacilitiesIcon, .smAccountsIcon, .smStatusCodesIcon, .smRolesAndPermissionsIcon, .smUsersIcon, .smNumberRangesIcon, .smAgentTypesIcon, .smHsCodesIcon {
    fill: $Grey-Shadow;
  }
  .arrowRightIcon{
    fill: none;
    stroke: $primary-default-dark;
    stroke-width: 1.5;
  }
  .smNumberRangesIcon {
    position: relative;
    top: 4px;
    left: 10px;
  }
}

.search-option-icons {
  .pinIcon {
    @include margin(10px, left);
  }

  .advancedSearchIcon {
    @include margin(3px, left);
  }

  .hamburgerIcon {
    @include margin(3px, left);
    @include margin(2px, top);
  }
}

.recent-list-action-icons {
  .hamburgerRecentListDeleteIcon {
    @include fill(none);
    @include margin(4px, bottom);
  }
  .hamburgerRecentListSaveIcon, .hamburgerRecentListPinIcon {
    @include fill($primary-default);
  }
  .isDisableActionIcon .hamburgerRecentListPinIcon {
    @include fill($Grey-Cloud);
  }
}

.dropdown-close-icon {
  .closeIcon {
    @include fill($Grey-Slate);
    @include position(relative);
    @include position-top(5px);
  }
}

.widget-icon {
  .blockIcon {
    fill-rule: evenodd;
    fill: $Secondary-Dark;
  }
  .flightNumbersIcon {
    @include padding-all(6px, 6px, 2px, 4px);
  }
  .manifestNumbersIcon {
    @include padding-all(6px, 3px, 0px, 9px);
  }
  .flightNumbersIcon, .manifestNumbersIcon {
    fill-rule: evenodd;
    fill: $Secondary-Dark;
  }
}

.help-icon-primary {
  .infoCircleIcon {
    fill: $primary-default;
  }
}

.createDuplicateShipmentIcon {
  stroke-width: 0 !important;
}

.logout {
  .logoutIcon {
    fill: $primary-default-dark;
  }
}

.lookupSearch-icon {
  .lookupSearchIcon {
    fill: $primary-default-dark;
  }
}

.svg-icon.mailIcon{
  svg {
    position: relative;
    top: 3px;
    left: 1px;
  }
}

.visibility {
  .visibilityTrueIcon  {
    position: relative;
    left: -8px;
    fill: $Grey-Shadow;
  }
}

.customEdit{
  &.active-card {
    .editIcon{
      fill: none;
      stroke: $primary-default-dark;
    }
  }
  &.inactive-card {
    .editIcon {
      fill: none;
      stroke: $Label;
    }
  }
}

.banner-info-icon {
  .infoCircleIcon {
    position: relative;
    top: 3px;
  }
  .infoWarningIcon {
    fill: $Status-Warning;
  }
}

.date-picker-icon {
  .datePickerIcon {
    position: relative;
    left: -1px;
    top: 2.5px;
  }
}