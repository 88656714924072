@import '../variable.scss';
@import '../mixins.scss';

.mat-sm {
  mat-icon {
    @include font-size(12px);
    @include color($Grey-Gray);
    @include cursor(pointer);
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    .mat-icon.notranslate.material-icons.mat-ligature-font.mat-icon-no-color {
      @include padding(0, top);
      @include padding(0, right);
    }
  }

  .mat-mdc-form-field-icon-suffix {
    @include position-bottom(6px);
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    @include height(24px);
    @include font-size(12px);
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      @include padding-imp(4px, top);
    }
  }

  &.notes-custom-datepicker{
    mat-datepicker-toggle{
      mat-icon{
          @include padding-imp(0px, top);
          @include position(relative);
          @include position-top(-9px);
          svg{
              @include height(18px) ;
              @include width(18px) ;
          }
      }
    }
    @include width(160px);
    --mdc-outlined-text-field-outline-color: #{$primary-default};

  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined.mdc-text-field--disabled {
    @include background($shadow-table-header);
  }

  .custom-error-tooltip-container {
    @include position-bottom(28px !important);
  }

  &.widgets-input {
    .mat-mdc-form-field-subscript-wrapper{
      @include display(none);
    }
  }

  &.attachment-type {
    .dropdown-icon {
      @include font-size(18px);
      @include color($Grey-Slate);
      @include padding-imp(10px, left);
      @include padding-imp(3px, top);
     }
  }

  &.widget-select {
    .mat-mdc-select-arrow-wrapper {
      @include display(none);
    }
    .mat-mdc-select-value-text {
      @include position(relative);
      @include position-top(2px);
    }
    .dropdown-icon {
      @include font-size(16px);
      @include color($Grey-Slate);
      @include padding-imp(10px, left);
      @include padding-imp(0px, bottom);
     }
    @include respond-to(tablet-landscape) {
      @include padding(40px, left);
      border: 2px solid;
    }
    @include respond-to(desktop) {
      @include margin(0px, left);
      border: 2px solid;
    }
  }

 &.remove-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align{
      @include display(none);
    }
  }

  .dropdown-icon {
    @include font-size(18px);
    @include color($Grey-Slate);
    @include padding-imp(3px, left);
    @include padding-imp(3px, top);
   }

  --mat-form-field-container-height: 26px;
  --mdc-outlined-text-field-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-hover-outline-color: #{$primary-default};
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-disabled-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-disabled-input-text-color: #{$Disabled-Label-Input};
  --mdc-outlined-text-field-disabled-label-text-color: #{$Disabled-Label-Input};
  --mdc-outlined-text-field-label-text-color: #{$Grey-Shadow};
}

.mat-md {
  &.hyperlink {
    --mdc-outlined-text-field-outline-color: #{$transparent};
    --mdc-outlined-text-field-hover-outline-color: #{$transparent};
    --mdc-outlined-text-field-focus-outline-color: #{$transparent};
    --mdc-outlined-text-field-disabled-outline-color: #{$transparent};
    .mat-mdc-text-field-wrapper {
      &.mdc-text-field {
        @include background-color($Details-hawb);
        :active{
          @include color($primary-default);
        }
        @include border-bottom-right-radius(4px);
        @include border-bottom-left-radius(4px);
      }
    }
    .mdc-text-field__input {
      @include cursor(pointer);
      @include color($Link !important);
      @include text-decoration(underline);
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined.mdc-text-field--disabled {
      @include background($Details-hawb);
    }
  }
  mat-icon {
    @include font-size(16px);
    @include color($Grey-Gray);
    @include cursor(pointer);

    &.dropdown-icon {
      @include font-size(12px);
      @include color($Grey-Slate);
      @include position(relative);
      @include position-left(10px);
      @include position-top(4px);
    }
  }

  &.custom-datepicker {
    mat-datepicker-toggle {
      mat-icon {
          @include padding-imp(0px, top);
          @include color($Grey-Slate);
      }
    }
    --mdc-outlined-text-field-outline-color: #{$primary-default};
    .mat-mdc-icon-button.mat-mdc-button-base {
      @include padding-hor-ver(10px, 12px);
      --mdc-icon-button-state-layer-size: 36px;
    }

    &.flight-misc, &.remove-focus {
      --mdc-outlined-text-field-outline-color: #{$Grey-Gray};
    }

    @include respond-to(tab-portrait){
      .mdc-floating-label{
        @include max-width(85px);
        &.mdc-floating-label--float-above{
          @include max-width(160px);
      }
    }
  }
}

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    @include position-bottom(5px);

    .mat-icon.notranslate.material-icons.mat-ligature-font.mat-icon-no-color {
      @include padding-hor-ver(0, 0);
      @include padding(10px, top);
      @include padding(0, right);
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    @include height(36px);
    @include font-size(14px);
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      @include padding-imp(12px, top);
    }
  }

  .mat-mdc-form-field-error-wrapper {
    @include padding-hor-ver(0, 0 !important);
    @include line-height(9px);
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined.mdc-text-field--disabled {
    @include background($shadow-table-header);
  }

  @media screen and (max-width: 1400px) {
    &.dimension {
      :has(.mat-mdc-form-field-icon-suffix) {
        .mdc-floating-label.mat-mdc-floating-label {
          max-width: 75px;
          width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          &.mdc-floating-label--float-above {
            max-width: 160px;
          }
        }
      }
    }
  }

  &.roles-form-field , &.mat-select-variants, &.custom-object-org-select {
    .mat-mdc-select-arrow-wrapper {
      @include display(none);
    }
    .dropdown-icon {
      @include font-size(18px);
      @include color($Grey-Slate);
      @include padding-imp(5px, right);
      @include position-top(0px);
     }
  }

  &.custom-select {
    .mat-mdc-select-arrow-wrapper {
      @include display(none);
    }
  }

  &.custom-object-org-select{
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
      @include borderRadiusAllEdges (0, 0, 0, 0);
    }
  }

  &.custom-object-org-input {
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
      @include borderRadiusAllEdges (0, 0, 0, 0);
    }
  }


  

  --mat-form-field-container-height: 36px;
  --mdc-outlined-text-field-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-hover-outline-color: #{$primary-default};
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-disabled-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-disabled-input-text-color: #{$Disabled-Label-Input};
  --mdc-outlined-text-field-disabled-label-text-color: #{$Disabled-Label-Input};
  --mdc-outlined-text-field-label-text-color: #{$Grey-Shadow};

  &.basic-search-textfield, &.addressFields, &.remove-subscript-wrapper {
    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align{
      @include display(none);
    }
  }

  &.sm-search-textfield{
    @include background($Shadow-Marble);
    .mat-mdc-form-field-subscript-wrapper {
      @include display(none);
    }
  }

  &.custom-textfield {
    .mdc-floating-label{
        @include max-width(100px);
        &.mdc-floating-label--float-above{
          @include max-width(160px);
        }
    }
  }

  &.bulk-bag-form-fields {
    &.destination, &.origin {
      .mat-mdc-form-field-infix {
        .mat-mdc-input-element {
          @include max-width(20vw);
          @include text-overflow(ellipsis);
        }
      }
    }
  }  

  }


.mat-lg {
  mat-icon {
    @include font-size(18px);
    @include color($Grey-Gray);
    @include cursor(pointer);
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    @include position(relative);

    .mat-icon.notranslate.material-icons.mat-ligature-font.mat-icon-no-color {
      @include padding(4px, top);
      @include padding(0, right);
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    @include height(42px);
    @include font-size(14px);
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      @include padding-imp(10px, top);
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined.mdc-text-field--disabled {
    @include background($shadow-table-header);
  }

  --mat-form-field-container-height: 42px;
  --mdc-outlined-text-field-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-hover-outline-color: #{$primary-default};
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-disabled-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-disabled-input-text-color: #{$Disabled-Label-Input};
  --mdc-outlined-text-field-disabled-label-text-color: #{$Disabled-Label-Input};
}

.table-input-field {

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    @include padding(2px, top);
  }

  .mat-mdc-form-field-subscript-wrapper {
    @include display(none !important);
  }
}

.input-wrapper-none {
  .mat-mdc-form-field-subscript-wrapper {
    @include display(none !important);
  }
}

.mat-sm-select {
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
    @include padding(0 !important, top);
  }
}

.public-note-textarea {
  --mdc-outlined-text-field-outline-color: #{$Secondary-Lightest};
  --mdc-outlined-text-field-focus-outline-color: #{$Secondary-Lightest};
  --mdc-outlined-text-field-hover-outline-color: #{$Secondary-Lightest};
  --mdc-outlined-text-field-focus-outline-width: 1px;
}

.private-note-textarea {
  --mdc-outlined-text-field-outline-color: #{$private-notes};
  --mdc-outlined-text-field-focus-outline-color: #{$private-notes};
  --mdc-outlined-text-field-hover-outline-color: #{$private-notes};
  --mdc-outlined-text-field-focus-outline-width: 1px;
}

.notes-textarea, .edit-notes-textarea {
  --mdc-outlined-text-field-outline-color: #{$transparent};
  --mdc-outlined-text-field-hover-outline-color: #{$transparent};
  --mdc-outlined-text-field-focus-outline-color: #{$transparent};

  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading, .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch, .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border: none !important;
  }
}

.notes-textarea {
  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mdc-text-field--outlined{
    padding-right: 0px;
  }
}

.edit-notes-textarea {
  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
  }

  textarea {
    font-size: 14px;
  }
}

.mat-md.hawb-description-textarea {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    @include height(52px);
  }
}


.chip-autocomplete-field {
  --mdc-outlined-text-field-outline-color: #{$Grey-Gray};
  --mdc-outlined-text-field-hover-outline-color: #{$primary-default};
  --mdc-outlined-text-field-focus-outline-width: 1px;

  mat-chip-grid {
      @include max-height(100px);
      @include height(70px);
      @include overflow-y(auto);
  }
  input {
      @include padding(10px,top);
  }
}