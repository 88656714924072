@import '../variable.scss';
@import '../mixins.scss';

.ibc-customise-tab {
  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    @include borderVal(none);
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    @include font-weight(700);
    @include font-size(14px);
  }

  .mat-mdc-tab .mdc-tab__text-label {
    @include font-weight(400);
    @include font-size(14px);
  }

  --mat-tab-header-inactive-label-text-color: #{$Default-widget-accordion-text
    !important};
  --mat-tab-header-inactive-hover-label-text-color: #{$Secondary-Default
    !important};
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  @include font-weight(700);
  @include font-size(16px);
}

.tab-icons-container {
  .mdc-tab.mat-mdc-tab.mdc-tab:last-of-type {
    @include display(flex);
    @include justify-content(flex-end, 1);
    @include cursor(pointer);
    @include pointer(none);
  }
}

.field-action-level-tabs-container {
  .mat-mdc-tab-labels {
    @include width(50vw);
    @include border-all(2px,solid, $primary-default-dark);
    @include borderRadius(4px);
    .mdc-tab .mat-mdc-tab {
      --mdc-tab-indicator-active-indicator-color: #{$primary-default-dark} !important;
      --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
      --mat-tab-header-pagination-icon-color: #000;
      --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
      --mat-tab-header-active-label-text-color: #{$primary-default-dark};
      --mat-tab-header-active-ripple-color: #{$primary-default-dark};
      --mat-tab-header-inactive-ripple-color: #{$primary-default-dark};
      --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
      --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
      --mat-tab-header-active-focus-label-text-color: #{$primary-default-dark};
      --mat-tab-header-active-hover-label-text-color: #{$primary-default-dark};
      --mat-tab-header-active-focus-indicator-color: #{$primary-default-dark};
      --mat-tab-header-active-hover-indicator-color: #{$primary-default-dark};
    }
    .mdc-tab__text-label{
      @include font-size(16px);
      @include font-weight(500);
    }
    .mdc-tab--active.mdc-tab-indicator--active {
      @include background($primary-default-dark);
      .mdc-tab__text-label{
        @include color($Shadow-Marble);
      }
    }
    .mat-mdc-focus-indicator {
      .mdc-tab__text-label{
        @include color($primary-default-dark);
      }
    }
    .mdc-tab-indicator {
      @include display(none);
    }
  }
}