.mat-mdc-snack-bar-container {
  opacity: 0 !important;
  .mdc-snackbar__surface {
    @include padding(6px, right);
  }

  .mdc-snackbar__label {
    @include padding-all(16px, 3px, 16px, 12px);
  }

  &.success-snackbar {
    --mdc-snackbar-container-color: #{$Status-Success};
    --mat-mdc-snack-bar-button-color: #{$Shadow-Marble};
    --mdc-snackbar-supporting-text-color: #{$Shadow-Marble};
  }

  &.error-snackbar {
    --mdc-snackbar-container-color: #{$Status-Danger};
    --mat-mdc-snack-bar-button-color: #{$Shadow-Marble};
    --mdc-snackbar-supporting-text-color: #{$Shadow-Marble};
  }

  &.warning-snackbar {
    --mdc-snackbar-container-color: #{$Status-Warning};
    --mat-mdc-snack-bar-button-color: #{$Shadow-Marble};
    --mdc-snackbar-supporting-text-color: #{$Shadow-Marble};
  }
}

.mat-mdc-snack-bar-container.show, .mat-mdc-snack-bar-container.show-error {
  opacity: 1 !important;
  animation-name: move;
  animation-duration: 1s;
  animation-timing-function: ease-in;
}

@keyframes move {
  from {
    transform: translateX(350px);
  }
  to {
    transform: translateX(0);
  }
}
