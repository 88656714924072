@mixin typography($fontsize, $fontweight, $lineheight, $color) {
  font-size: $fontsize !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: $fontweight !important;
  line-height: $lineheight !important;
  @include color($color);
}

@mixin font-family($value) {
  font-family: $value;
}

@mixin color($color) {
  color: $color;
}

@mixin background($color) {
  background: $color;
}

@mixin background-Imp($color) {
  background: $color !important;
}

@mixin background-color($color) {
  background-color: $color;
}

@mixin background-color-opacity($color, $opacity){
    $rgba: rgba(red($color), green($color), blue($color), $opacity);
    @include background-Imp($rgba);
}

@mixin borderRadius($size) {
  border-radius: $size;
}

@mixin borderRadiusAllEdges($top-left, $top-right, $bottom-right, $bottom-left){
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin borderRadiusImp($size) {
  border-radius: $size !important;
}

@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}

@mixin borderImp($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color !important;
}

@mixin border-all($width, $style, $color) {
  border: $width $style $color;
}
@mixin border-all-opacity($width, $style, $color, $opacity){
  $rgba: rgba(red($color), green($color), blue($color), $opacity);
  border: $width $style $rgba;
}

@mixin border-none() {
  border: none;
}

@mixin border-direction-none($direction) {
  border-#{$direction}: none;
}

@mixin borderWidth($size) {
  border-width: $size;
}

@mixin border-collapse() {
  border-collapse: collapse !important;
}

@mixin padding($number, $direction) {
  padding-#{$direction}: $number;
}

@mixin padding-imp($number, $direction) {
  padding-#{$direction}: $number !important;
}

@mixin padding-hor-ver($ver, $hor) {
  padding: $ver $hor;
}

@mixin padding-hor-ver-imp($ver, $hor) {
  padding: $ver $hor !important;
}

@mixin padding-all($top, $right, $bottom, $left) {
  padding: $top $right $bottom $left;
}

@mixin margin($number, $direction) {
  margin-#{$direction}: $number;
}

@mixin margin-hor-ver($ver, $hor) {
  margin: $ver $hor;
}

@mixin margin-all($top, $right, $bottom, $left) {
  margin: $top $right $bottom $left;
}

@mixin display($display) {
  display: $display;
}

@mixin gap($gap) {
  gap: $gap;
}

@mixin flex-align($align) {
  align-items: $align;
}

@mixin flex-gap-align($display, $gap, $align) {
  display: $display;
  gap: $gap;
  align-items: $align;
}

@mixin flex-all($display, $gap, $justify, $align) {
  display: $display;
  gap: $gap;
  justify-content: $justify;
  align-items: $align;
}

@mixin cursor($val) {
  cursor: $val;
}

@mixin pointer($val) {
  pointer-events: $val;
}

@mixin svg-icon-w-h($val) {
  width: $val;
  height: $val;
}

@mixin box-shadow($val) {
  box-shadow: $val;
}

@mixin text-align($val) {
  text-align: $val;
}

@mixin text-decoration($val) {
  text-decoration: $val;
}

@mixin text-overflow($val){
  text-overflow: $val;
}

@mixin height($val) {
  height: $val;
}

@mixin height-Imp($val) {
  height: $val !important;
}

@mixin min-height($val) {
  min-height: $val;
}

@mixin max-height($val) {
  max-height: $val;
}

@mixin width($val) {
  width: $val;
}

@mixin min-width($val) {
  min-width: $val;
}

@mixin font-size($val) {
  font-size: $val;
}

@mixin position($position) {
  position: $position;
}

@mixin position-top($val) {
  top: $val;
}

@mixin position-bottom($val) {
  bottom: $val;
}

@mixin position-right($val) {
  right: $val;
}

@mixin position-left($val) {
  left: $val;
}

@mixin overflow($val) {
  overflow: $val;
}

@mixin overflow-x($val) {
  overflow-x: $val;
}

@mixin overflow-y($val) {
  overflow-y: $val;
}

@mixin white-space($val){
  white-space: $val;
}

@mixin widget_styles() {
  @include borderRadius(6px);
  @include box-shadow(0px 2px 16px 0px rgba(220, 209, 220, 0.8));
  @include flex-gap-align(flex, 20px, center);
  @include padding(10px, 'left');
  @include padding(10px, 'right');
}

@mixin flex-direction($val) {
  flex-direction: $val !important;
}

@mixin display-gap($display, $gap) {
  display: $display;
  gap: $gap;
}

@mixin borderVal($val) {
  border: $val;
}

@mixin grid-template-column($val) {
  grid-template-columns: $val;
}

@mixin grid-template-row($val){
  grid-template-rows: $val;
}

@mixin column-gap($val) {
  column-gap: $val;
}
@mixin row-gap($val) {
  row-gap: $val;
}
@mixin grid-row($val){
  grid-row: $val;
}
@mixin grid-column($val){
  grid-column: $val;
}

@mixin max-width($val) {
  max-width: $val;
}

@mixin justify-content($val, $number) {
  justify-content: $val;
  flex-grow: $number;
}

@mixin color-Imp($val) {
  color: $val !important;
}

@mixin font-weight($val) {
  font-weight: $val !important;
}

@mixin flex-wrap($val) {
  flex-wrap: $val;
}

@mixin flex-table-prop($val) {
  flex: $val;
}

@mixin flex-justify-content($val) {
  justify-content: $val;
}

@mixin line-height($lineheight) {
  line-height: $lineheight;
}

@mixin font-weight($fontweight) {
  font-weight: $fontweight;
}

@mixin letter-spacing($val) {
  letter-spacing: $val;
}

@mixin opacity($val) {
  opacity: $val;
}

@mixin float($val) {
  float: $val;
}

@mixin no-wrap($val) {
  white-space: $val;
}

@mixin flex-grow($val) {
  flex-grow: $val;
}

@mixin flex-basis($val) {
  flex-basis: $val;
}

@mixin background-color-opacity($color, $opacity) {
  $rgba: rgba(red($color), green($color), blue($color), $opacity);
  @include background-Imp($rgba);
}

@mixin content() {
  content: '';
}

@mixin contentVal($val) {
  content: $val;
}

@mixin borderStyle($val) {
  border-style: $val;
}

@mixin transform-rotate($val) {
  transform: rotate($val);
}

@mixin border-color($top, $right, $bottom, $left) {
  border-color: $top $right $bottom $left;
}

@mixin border-all-opacity($width, $style, $color, $opacity){
  $rgba: rgba(red($color), green($color), blue($color), $opacity);
  border: $width $style $rgba;
}

@mixin background-color-opacity($color, $opacity){
  $rgba: rgba(red($color), green($color), blue($color), $opacity);
  @include background-Imp($rgba);
}

@mixin borderRadiusAllEdges($top-left, $top-right, $bottom-right, $bottom-left){
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin max-height($val) {
  max-height: $val;
}

@mixin word-break($val) {
  word-break: $val;
}

@mixin text-overflow($val) {
  text-overflow: $val;
}

@mixin text-wrap($val) {
  text-wrap: $val;
}

@mixin border-bottom-right-radius($val) {
  border-bottom-right-radius: $val;
}

@mixin border-bottom-left-radius($val) {
  border-bottom-left-radius: $val;
}

@mixin z-index($val) {
  z-index: $val;
}

@mixin transition($val) {
  transition: $val;
}

@mixin border-bottom($val) {
  border-bottom: $val;
}

@mixin justify-items($val){
  justify-items: $val;
}

@mixin bottom($val){
  bottom: $val;
}

@mixin right($val){
  right: $val;
}

@mixin fill($val) {
  fill: $val;
}

@mixin align-self($val) {
  align-self: $val;
}

@mixin respond-to($media) {
  @if $media == tab-landscape {
    @media (max-width: $desktop) and (min-width: $tablet-landscape) {
      @content
    }
  } @else if $media == tab-portrait {
    @media (max-width: $tablet-landscape) and (min-width: $tablet-potrait) {
      @content
    }
  }
}

@mixin grid-column-start($val) {
  grid-column-start: $val;
}