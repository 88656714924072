@import '../variable.scss';

.mat-calendar-body-selected {
    background-color: #{$primary-default-dark} !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #{$Secondary-Default} !important;
}

.mat-calendar-body-cell {
   --mat-datepicker-calendar-date-hover-state-background-color: #{$OnSidebarHover};
}
