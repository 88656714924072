@import '../mixins.scss';

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    @include padding-hor-ver(0, 0);
    @include width(160px);
    @include max-height(36vh);

    .mat-mdc-menu-item {
      @include min-height(40px);
      @include padding(6px, left);
      @include padding(6px, right);

      .mat-mdc-menu-item-text .mdc-checkbox {
        @include padding(8px, right);
      }
    }
  }
}

.app-header-menu {
  @include width(20vw);
  .mat-mdc-menu-content {
    @include padding-hor-ver(0, 0);
    @include width(100%);
    @include max-height(none);
  }
  mat-card-header {
    @include background-color($Other-Blue);
    @include padding-all(10px, 10px, 10px, 10px !important);
    @include flex-all(flex, 10px, flex-start, center);
    .mat-mdc-card-avatar {
      @include background-color($Other-Yellow);
      @include display(flex);
      @include flex-all(flex, 0px, center, center);
      @include margin(0px, bottom);
      span {
        @include typography(16px, 400, normal, $Grey-Charcoal);
      }
    }
    .mat-mdc-card-avatar~.mat-mdc-card-header-text{
      .mat-mdc-card-subtitle, .mat-mdc-card-title {
        @include padding-all(0px, 0px, 0px, 0px);
      }
    }
  }
  .mdc-card__actions {
    @include display-gap(flex, 0px);
    @include flex-direction(column);
    @include padding-all(0,0,0,0);
    button {
      @include width(100%);
      @include typography(14px, 400, normal, $Grey-Dark);
      @include display(flex);
      @include flex-justify-content(flex-start);
      @include padding-all(25px,20px,25px,20px);
    }
  }
}
