@import '../variable.scss';
@import '../mixins.scss';

.mat-mdc-raised-button.mat-mdc-button-base {
  @include box-shadow(0px 1px 1px 0px rgba(0, 0, 0, 0.1));
}

.mat-mdc-button.mat-mdc-button-base.mat-button-sm,
.mat-mdc-raised-button.mat-mdc-button-base.mat-button-sm,
.mat-mdc-button.mat-mdc-button-base.mat-button-sm,
.mat-mdc-unelevated-button.mat-mdc-button-base.mat-button-sm,
.mat-mdc-outlined-button.mat-mdc-button-base.mat-button-sm {
  @include height(26px);
  @include padding-hor-ver(8px, 16px);
  @include font-size(13px);
  @include borderRadius(4px);
}

.mat-mdc-button.mat-mdc-button-base.mat-button-md,
.mat-mdc-raised-button.mat-mdc-button-base.mat-button-md,
.mat-mdc-button.mat-mdc-button-base.mat-button-md,
.mat-mdc-unelevated-button.mat-mdc-button-base.mat-button-md,
.mat-mdc-outlined-button.mat-mdc-button-base.mat-button-md {
  @include height(36px);
  @include padding-hor-ver(8px, 16px);
  @include font-size(14px);
  @include borderRadius(4px);
}

.mat-mdc-button.mat-mdc-button-base.mat-button-lg,
.mat-mdc-raised-button.mat-mdc-button-base.mat-button-lg,
.mat-mdc-button.mat-mdc-button-base.mat-button-lg,
.mat-mdc-unelevated-button.mat-mdc-button-base.mat-button-lg,
.mat-mdc-outlined-button.mat-mdc-button-base.mat-button-lg {
  @include height(48px);
  @include padding-hor-ver(8px, 16px);
  @include font-size(20px);
  @include borderRadius(4px);
}

.mat-primary.mat-mdc-raised-button.mat-mdc-button-base.mat-button-sm,
.mat-primary.mat-mdc-raised-button.mat-mdc-button-base.mat-button-md,
.mat-primary.mat-mdc-raised-button.mat-mdc-button-base.mat-button-lg {
  &:hover {
    @include background-color($primary-button);
    @include box-shadow(0px 1px 1px 0px rgba(0, 0, 0, 0.1));
  }
  &:focus {
    @include background-color($primary-button-default);
    @include box-shadow(0px 1px 1px 0px rgba(0, 0, 0, 0.1));
  }
  &:disabled {
    @include background-color($Grey-Gray);
    @include box-shadow(0px 1px 1px 0px rgba(0, 0, 0, 0.1));
  }
}

.mat-button-toggle {
  @include borderRadiusImp(16px);

  &.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    @include height(24px);
    line-height: 26px;
  }

  &.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    @include border-all(1px, solid, $primary-default);
  }

  :hover {
    background-color: $Secondary-Default;
    color: $Shadow-Marble !important;
  }

  --mat-standard-button-toggle-selected-state-background-color: #{$primary-default};
  --mat-standard-button-toggle-selected-state-text-color: #{$Shadow-Marble !important} ;
}

.custom-toggle {
  .mat-mdc-slide-toggle{	
    --mdc-switch-unselected-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-focus-state-layer-color: #{$primary-default-dark}; 
      --mdc-switch-selected-hover-state-layer-color: #{$primary-default-dark}; 
      --mdc-switch-selected-pressed-state-layer-color: #{$primary-default-dark}; 
      --mdc-switch-selected-focus-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-hover-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-pressed-handle-color: #{$primary-default-dark}; 
      --mdc-switch-unselected-focus-handle-color: #{$primary-default-dark}; 
      --mdc-switch-unselected-focus-state-layer-color: #{$primary-default-dark}; 
      --mdc-switch-unselected-pressed-handle-color: #{$primary-default-dark}; 
      --mdc-switch-unselected-hover-handle-color: #{$primary-default-dark}; 
      --mat-full-pseudo-checkbox-selected-icon-color: #{$primary-default-dark}; 
      --mdc-switch-selected-track-color: #{$Other-Blue};
      --mdc-switch-unselected-track-color: #{$Other-Blue};
      --mdc-switch-unselected-hover-track-color: #{$Other-Blue};
      --mdc-switch-selected-track-color: #{$Other-Blue};
      --mdc-switch-selected-focus-track-color: #{$Other-Blue};
      --mdc-switch-selected-hover-track-color: #{$Other-Blue};
      --mdc-switch-selected-pressed-track-color: #{$Other-Blue};
      --mdc-switch-unselected-focus-track-color: #{$Other-Blue};
      --mdc-switch-unselected-pressed-state-layer-color: #{$Shadow-Marble}; 
      --mdc-switch-unselected-pressed-track-color: #{$Other-Blue};
  }
  .mdc-switch .mdc-switch__track {
    @include border-all-opacity(2px, solid, $Secondary-Default, 60%);
    @include borderRadius(15px);
  }
  .mdc-switch__icons {
    @include display(none);
  }
}

.mat-mdc-icon-button {
  --mat-icon-button-hover-state-layer-opacity: 0 !important;
  --mat-icon-button-focus-state-layer-opacity: 0 !important;
  --mat-icon-button-pressed-state-layer-opacity: 0 !important;
}

.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #{$primary-default-dark !important};
}

.custom-translation-toggle {
  .mat-mdc-slide-toggle{	
    --mdc-switch-unselected-handle-color: #{$Grey-Cloud}; 
      --mdc-switch-selected-focus-state-layer-color: #{$Grey-Cloud}; 
      --mdc-switch-selected-hover-state-layer-color: #{$primary-default-dark}; 
      --mdc-switch-selected-pressed-state-layer-color: #{$primary-default-dark}; 
      --mdc-switch-selected-focus-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-hover-handle-color: #{$primary-default-dark}; 
      --mdc-switch-selected-pressed-handle-color: #{$Grey-Cloud}; 
      --mdc-switch-unselected-focus-handle-color: #{$Grey-Cloud}; 
      --mdc-switch-unselected-focus-state-layer-color: #{$Grey-Cloud}; 
      --mdc-switch-unselected-pressed-handle-color: #{$Shadow-Marble}; 
      --mdc-switch-unselected-hover-handle-color: #{$Grey-Cloud}; 
      --mat-full-pseudo-checkbox-selected-icon-color: #{$primary-default-dark}; 
      --mdc-switch-selected-track-color: #{$Other-Blue};
      --mdc-switch-unselected-track-color: #{$Shadow-Marble};
      --mdc-switch-unselected-hover-track-color: #{$Shadow-Marble};
      --mdc-switch-selected-track-color: #{$Other-Blue};
      --mdc-switch-selected-focus-track-color: #{$Other-Blue};
      --mdc-switch-selected-hover-track-color: #{$Other-Blue};
      --mdc-switch-selected-pressed-track-color: #{$Other-Blue};
      --mdc-switch-unselected-focus-track-color: #{$Shadow-Marble};
      --mdc-switch-unselected-pressed-state-layer-color: #{$Shadow-Marble}; 
      --mdc-switch-unselected-pressed-track-color: #{$Shadow-Marble};
      .mdc-switch {
        .mdc-switch__track {
          @include borderRadius(15px);
        }
        &.mdc-switch--unselected {
          .mdc-switch__track {
            @include border-all(2px, solid, $Grey-Lighter);
          }
        } 
        &.mdc-switch--selected.mdc-switch--checked {
          .mdc-switch__track {
            @include border-all-opacity(2px, solid, $Secondary-Default, 60%);
          }
        }
      }
      .mdc-switch__handle {
        @include position(relative);
        @include position-top(8.80px);
      }
  }
}