@import 'src/styles/mixins.scss';

.pr-16 {
  @include padding(16px, 'right');
}

.pr-40 {
  @include padding(40px, 'right');
}

.mr-4 {
  @include margin(4px, 'right');
}

.ml-28 {
  @include margin(28px, 'left');
}
