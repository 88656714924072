@import '../variable.scss';
@import '../mixins.scss';

.mat-mdc-tooltip-panel-right {
    --mdc-plain-tooltip-container-color:#{$Grey-Charcoal
        !important};
        --mdc-plain-tooltip-container-shape: 6px;
        .mdc-tooltip__surface {
            padding: 8px 8px;
        }
}

.custom-tooltip-notes-user.mat-mdc-tooltip {
    white-space: pre-line;
}

.custom-tooltip-consignee.mat-mdc-tooltip {
  white-space: pre-line;
}

.custom-tooltip-consignee {
  .mdc-tooltip__surface {
    max-width: 400px;
    text-align: start;
  }
}

.custom-tooltip-shipment-status.mat-mdc-tooltip {
  white-space: pre-line;
  @include margin(3px, 'bottom');
}

.custom-tooltip-shipment-status {
  .mdc-tooltip__surface {
    max-width: 400px;
    @include text-align(left);
    @include line-height(25px!important);
  }
}
