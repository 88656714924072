.ibc-primary-default-button {
  @include height(26px);
  @include padding-hor-ver(5px, 16px);
  @include borderRadius(4px);
  @include color($Shadow-Marble !important);
  @include background-color($primary-default-dark !important);
  @include borderVal(1px solid $primary-default-dark !important);

  &:hover {
    @include background-color($primary-button !important);
    @include borderVal(1px solid $primary-button !important);
  }

  &:pressed {
    @include background-color($primary-button-default !important);
  }

  &:disabled {
    @include color($Shadow-Marble !important);
    @include background-color($Grey-Gray !important);
    @include borderVal(1px solid $Grey-Gray !important);
  }
}

.ibc-secondary-default-button {
  @include height(26px);
  @include padding-hor-ver(5px, 16px);
  @include borderRadius(4px);
  @include color($Default-Dark !important);
  @include background-color($Shadow-Marble !important);
  @include borderVal(1px solid $primary-default-dark !important);

  &:hover {
    @include background-color($Secondary-background !important);
    @include borderVal(1px solid $Secondary-background !important);
  }

  &:pressed {
    @include background-color($Grey-Gray !important);
  }

  &:disabled {
    @include color($Grey-Cloud !important);
    @include background-color($Shadow-Marble !important);
    @include borderVal(1px solid $Grey-Gray !important);
  }
}

.ibc-secondary-default-button.popup-row-button {
  @include color($primary-default-dark !important);
  @include borderVal(1px solid $primary-default-dark !important);
}

.ibc-caution-default-button {
  @include height(26px);
  @include padding-hor-ver(5px, 16px);
  @include borderRadius(4px);
  @include color($Shadow-Marble !important);
  @include background-color($Status-Danger !important);
  @include borderVal(1px solid $Status-Danger !important);

  &:hover {
    @include background-color($Critical-Hover !important);
    @include borderVal(1px solid $Critical-Hover !important);
  }

  &:pressed {
    @include background-color($Critical-Pressed !important);
  }

  &:disabled {
    @include color($Shadow-Marble !important);
    @include background-color($Critical-Disabled !important);
    @include borderVal(1px solid $Critical-Disabled !important);
  }
}

.ibc-caution-secondary-button {
  @include height(26px);
  @include padding-hor-ver(5px, 16px);
  @include borderRadius(4px);
  @include color($Status-Danger !important);
  @include background-color($Shadow-Marble !important);
  @include borderVal(1px solid $Status-Danger !important);

  &:hover {
    @include background-color($Secondary-background !important);
    @include borderVal(1px solid $Secondary-background !important);
  }

  &:pressed {
    @include background-color($Grey-Gray !important);
  }

  &:disabled {
    @include color($Grey-Cloud !important);
    @include background-color($Shadow-Marble !important);
    @include borderVal(1px solid $Grey-Gray !important);
  }
}

.ibc-medium-button {
  @include height(36px);
  @include padding-hor-ver(8px, 16px);
}

.ibc-large-primary-default-button {
  @include height(48px);
}

.ibc-large-secondary-default-button {
  @include height(48px);
}

.ibc-snakbar-ok-button {
  @include height(27px);
  @include background-color-opacity($Shadow-Marble, 20%);
  @include borderRadius(4px);
  @include padding-hor-ver(5px, 10px);
  @include border-none();
  @include typography(16px, 700, normal, $Shadow-Marble);
  @include letter-spacing(0.5px);
  @include cursor(pointer);
}

.ibc-secondary-accent-button {
  @include height(26px);
  @include padding-hor-ver(5px, 16px);
  @include borderRadius(4px);
  @include color($Shadow-Marble !important);
  @include background-color($Secondary-Default !important);
  @include borderVal(1px solid $Secondary-Default !important);

  &:hover {
    @include background-color($Secondary-Accent-Background !important);
    @include borderVal(1px solid $Secondary-Accent-Background !important);
  }

  &:pressed {
    @include background-color($Secondary-Accent-Background-Dark !important);
  }

  &:disabled {
    @include color($Grey-Cloud !important);
    @include background-color($Shadow-Marble !important);
    @include borderVal(1px solid $Grey-Gray !important);
  }
}
.ibc-secondary-accent-button-md {
  @include height(36px);
  @include padding-hor-ver(5px, 16px);
  @include borderRadius(4px);
  @include color($Shadow-Marble !important);
  @include background-color($Secondary-Default !important);
  @include borderVal(1px solid $Secondary-Default !important);

  &:hover {
    @include background-color($Secondary-Accent-Background !important);
    @include borderVal(1px solid $Secondary-Accent-Background !important);
  }

  &:pressed {
    @include background-color($Secondary-Accent-Background-Dark !important);
  }

  &:disabled {
    @include color($Grey-Cloud !important);
    @include background-color($Shadow-Marble !important);
    @include borderVal(1px solid $Grey-Gray !important);
  }

  span.ibc-body-text-regular {
    @include color($Shadow-Marble !important);
  }
}
