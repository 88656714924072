@import './buttons.scss';
@import './textfields.scss';
@import '/src/styles/material/theming.scss';
@import '/src/styles/material/sidebar.scss';
@import './card.scss';
@import './checkbox.scss';
@import './table.scss';
@import './menu.scss';
@import './expansion-panel.scss';
@import './tabs.scss';
@import './snackbar.scss';
@import './tooltip.scss';
@import './radio.scss';
@import './datepicker.scss';
@import './stepper.scss';

$primary: map-get($primary-palette, 500);

.bg-color {
  background-color: $primary;
}

$pactrakTNG-primary: mat.define-palette($primary-palette);
$pactrakTNG-accent: mat.define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$pactrakTNG-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pactrakTNG-theme: mat.define-light-theme(
  (
    color: (
      primary: $pactrakTNG-primary,
      accent: $pactrakTNG-accent,
      warn: $pactrakTNG-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($pactrakTNG-theme);
