// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './styles/variable.scss';
@import './styles/typography.scss';
@import './styles/navigations.scss';
@import './styles/formcontrol.scss';
@import './styles/buttons.scss';
@import './styles/material/material.scss';
@import './styles/utility.scss';
@import './styles/widgets.scss';
@import './styles/svg-icon.scss';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  background: #f2f2f2;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.logo {
   @include flex-all(flex, 0, left, center);
   @include height(56px);
   @include background($Shadow-Marble);
}

.spinner-container {
  position: fixed;
  top: 0;
  z-index: 9999999;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
// card -layout
.wrapper-container {
  @include padding-all(16px, 24px, 16px, 24px);
}

.mt-1 {
  margin-top: 1%;
}
.w-100 {
  width: 100%;
}
.wrapper-container {
  @include padding-hor-ver(16px, 20px);
}

.card-title {
  @include padding(20px, 'left');
}

.card-action-icons {
  @include margin(auto, 'left');
}

.publish-btn-icon {
  @include display(flex);
  @include gap(16px);

  .ibc-medium-button {
    @include height(32px);
  }

  .ibc-primary-default-button {
      @include borderRadius(8px);
  }

  .ibc-body-text-regular {
      @include color($Shadow-Marble);
  }
}

.icon-section {
  @include display(flex);
  @include gap(4px);
}

.cursor {
  @include cursor(pointer);
}

.ibc-table-pagination {
  @include flex-all(flex !important, 0, flex-end, center);
  @include height(36px);
  @include padding(6px, top);
}

.w-full {
  @include width(100%);
}

.text-hyperlink {
  @include color($Link);
  @include text-decoration(underline);
  @include cursor(pointer);
}

.text-notes-hyperlink{
  @include color($primary-default);
  @include font-weight(bold);
  @include font-size(16px);
  @include text-decoration(underline);
  @include cursor(pointer);
}

.widget-cards-section {
  @include display(grid);
  @include grid-template-column(1fr 1fr 1fr 1fr);
  @include gap(20px);
  @include padding(20px, top);

  &.shipment-advanced-search {
    @include display(grid);
    @include grid-template-column(repeat(auto-fit, minmax(200px, 1fr)));
    .widgets {
      @include display-gap(flex, 10px);
    }
    // viewports for responsive designs for widgets

    @include respond-to(tab-landscape) {
      @include grid-template-column(1fr 1fr 1fr);
    }

    @include respond-to(tab-portrait) {
      @include grid-template-column(repeat(auto-fit, minmax(200px, 1fr)));
    }
  }

  .widgets {
    @include height(54px);
    @include borderRadius(6px);
    @include box-shadow(0px 2px 16px 0px rgba(220, 209, 220, 0.8));
    @include flex-gap-align(flex, 0, center);
    @include padding-all(0px, 12px, 0px, 12px);

    .widget-icon {
      @include borderRadius(4px);
      @include background(rgba(185, 226, 222, 0.2));
      @include display(flex);
      @include flex-align(center);
      @include margin(8px, right);
    }

    .ibc-heading-h7-bold-subtitle {
      @include color($Secondary-Dark);
    }

    .weight {
      @include padding-all(4px, 5px, 2px, 5px);
    }

    .hawbNumbers {
      @include padding-all(4px, 6px, 4px, 6px);
    }

    .pieces {
      @include padding-all(7px, 7px, 4px, 7px);
    }

    .totalValue {
      @include padding-all(6px, 6px, 6px, 6px);
    }

    .block {
      @include padding-all(5px, 10px, 3px, 6px);
    }

    .locationPin {
      @include padding-all(5px, 6px, 3px, 6px);
    }
  }
}

// note section hint while add action
.note-section {
  @include padding(20px, top);
  .ibc-body-text-large-bold {
    @include color($Grey-Dark);
  }

  .ibc-body-text-medium {
    @include line-height(20px !important);
    @include padding(8px, left);
  }
}

.ibc-error-message {
  @include font-size(11px);
}
.ibc-error-message-h6 {
  @include typography(16px, 400, normal, $Status-Danger);
}

.comparision-error {
  @include padding(8px, top);
  @include color(#f44336);
  @include font-size(11px !important);
}

.breadcrumb a {
  @include color($Link);
}

//notes
.notes-list-container {
  @include display-gap(flex, 16px);
  @include flex-direction(column);
  @include margin(12px, top);

  .note {
    @include display(grid);
    @include grid-template-column(80px 3fr auto);
    @include borderRadius(6px);

    .user-info {
      @include flex-all(flex, 4px, space-between, center);
      @include flex-direction(column);

      .user-badge {
        @include color-Imp($Shadow-Marble);
        @include width(100%);
        @include text-align(center);
        @include borderRadiusAllEdges(5px, 0px, 5px, 0px);
      }

      .avatar {
        @include height(30px);
        @include width(30px);
        @include borderRadius(50%);
        @include flex-all(flex, 0px, center, center);
        @include color-Imp($Shadow-Marble);
      }

      .name {
        @include flex-all(flex, 5px, center, center);
        @include padding-all(0px, 4px, 0px, 4px);

        span {
          @include width(50px);
          @include overflow(hidden);
          @include text-overflow(ellipsis);
          @include text-align(center);
        }
      }
    }

    &.customer {
      @include background-color-opacity($Secondary-Lightest, 20%);
      @include border-all-opacity(1px, solid, $Secondary-Lightest, 1);

      .user-badge {
        @include background-color($Secondary-Dark);
      }

      .avatar {
        @include background-color($Other-Pale);
      }
    }

    &.ibc {
      @include background-color-opacity($Other-Orange, 10%);
      @include border-all-opacity(1px, solid, $Other-Orange, 20%);

      .user-badge {
        @include background-color-opacity($Other-Orange, 80%);
      }

      .avatar {
        @include background-color($Other-Orange);
      }
    }

    .note-content {
      @include padding-all(10px, 10px, 10px, 10px);

      mat-form-field {
        @include width(100%);
      }
    }

    .actions {
      @include padding(10px, right);
      @include padding(10px, top);
      @include flex-all(flex, 0px, flex-start, flex-end);
      @include flex-direction(column);

      .action-btns {
        @include flex-gap-align(flex, 6px, flex-start);

        .notes-save-icon {
          @include margin(2px, top);
        }
      }
    }
  }
}

// custom tooltip overlay
.cdk-overlay-container {
  z-index: 10 !important;
}

//grid toggle search
.toggle-container{
  @include flex-gap-align(flex, 8px, baseline);
  @include padding(10px, bottom);
  .toggle-active {
    @include color($primary-default-dark);
  }
  .toggle-inactive{
    @include color($Default-widget-accordion-text);
  }
}

.error-icon {
  @include flex-gap-align(flex, 0, center);
}

.origin-field-icons.set-height .infoCircleIcon {
  @include margin(3px, bottom);
}

.destination-field-icons, .countryCode-field-icons, .state-field-icons {
  @include display(flex);
}

.destination-field-icons.set-height .infoCircleIcon {
  @include margin(5px, top);
}

.destination-field-icons.set-height .lookupSearchIcon,
.countryCode-field-icons.set-height .lookupSearchIcon,
.state-field-icons.set-height .lookupSearchIcon {
  @include margin(8px, top);
}

.fieldClearIcon, .infoCircleIcon, .lookupSearchIcon {
  @include padding(8px, right);
}

 .error-help-icon {
  @include flex-gap-align(flex, 0, flex-start);
 }

 .help-icon {
  @include color($primary-default !important);
  @include width(16px !important);
  @include height(16px !important);
  @include padding-imp(4px, top);
  @include padding-imp(4px, right);
 }

 /* table with action icons */
 .table-tabs-container {
  .table-tabs-header {
    @include display(flex);
    @include background($shadow-table-header);
    @include flex-align(center);
    @include border-all(1px, solid, $Grey-Lighter);
    @include borderRadius(6px 6px 0px 0px);
    @include padding-hor-ver(8px, 12px);

    .table-tabs-title {
      @include color($primary-default-dark);
      @include padding(12px, left);

      .table-tab-name {
        @include display(inline-block);
        @include border-bottom(2px solid $primary-default-dark);
      }
    }

    .tab-icons {
      @include margin(auto, left);
    }

    &.advanced-search-container-header {
     @include padding-hor-ver(12px, 12px);
    }
  }
}

/* popup footer */
.popup-footer, .search-actions, .crud-popup-footer {
  .ibc-primary-default-button .ibc-body-text-regular, .ibc-caution-default-button .ibc-body-text-regular {
    @include color($Shadow-Marble);
  }

  .ibc-secondary-default-button .ibc-body-text-regular {
    @include color($primary-default-dark);
  }

  .ibc-caution-secondary-button .ibc-body-text-regular {
    @include color($Status-Danger);
  }

  .ibc-secondary-default-button:hover .ibc-body-text-regular,
  .ibc-caution-secondary-button:hover .ibc-body-text-regular {
    @include color($Default-Dark);
  }

  .ibc-secondary-default-button:disabled .ibc-body-text-regular {
    @include color($Grey-Cloud !important);
  }
}

/* column name and sort icon */
.column-header {
  @include flex-gap-align(flex, 16px, center);
}

/* Table */
.selection-table, .crud-table {
  @include border-all(1px, solid, $Grey-Lighter);
  @include borderRadius(0px 0px 6px 6px);

  .mat-column-select {
    @include flex-table-prop(0 0 6vw);
  }

  .mat-column-action {
    @include flex-table-prop(0 0 10vw);
}
}

/* table field tooltip */
.table-input-field {
  mat-cell {
    @include display(grid);
    @include position(relative);
    @include overflow(visible);
  }
  mat-form-field{
    app-custom-error-tooltip {
      @include position(absolute);
      @include z-index(10);
    }
  }
}

.select-all-message {
  @include width(1140px);
  @include height(36px);
  @include text-align(center);
 }

 .shipment-gateway-master-shipments-table, .shipment-gateway-table {
  .select-all-message, .sort-icon {
    @include display(none);
  }
}

 .customInputFields:focus-within, .customInputFields:hover{
  @include border-all(1px, solid, $primary-default !important);
}

.customInputFields:focus-within legend, .customInputFields:hover legend{
  @include color($primary-default);
}

.customInputFields.fieldset-error{
  @include border-all(1px, solid, $Status-Danger !important);
  legend {
    @include color($Status-Danger);
  }
}
 /* summary table message text */
 .ibc-summary-table .initial-search-text, .lookup-table .initial-search-text {
  @include text-align(center);
  @include background-color($Secondary-Lighter);
  @include padding-hor-ver(10px, 0);
 }

// master shipment selection table
.shipment-gateway-master-shipments-table {
  .ibc-summary-table {
    .mat-column-masterShipment, .mat-column-masterShipmentConsigneeName,
    .mat-column-masterShipmentFinalDestination, .mat-column-masterShipmentDateOfCreation {
      @include flex-table-prop(0 0 12vw);
    }
    .mat-column-masterShipment {
      span {
        @include overflow(hidden);
        @include white-space(nowrap);
        @include text-overflow(ellipsis);
      }
    }

    .mat-column-actions {
      @include flex-table-prop(0 0 9vw);
    }
  }
}

//shipment selection table
.shipment-gateway-table {
  .ibc-summary-table {
    .mat-column-actions {
      @include flex-table-prop(0 0 14vw);
      @include respond-to(tab-portrait) {
        @include flex-table-prop(0 0 15vw);
      }
    }
    .mat-column-shipmentGatewayShipment, .mat-column-shipmentGatewayFinalDestination {
      @include flex-table-prop(0 0 10vw);
      @include respond-to(tab-portrait) {
        @include flex-table-prop(0 0 10vw);
      }
    }
    mat-row {
      .mat-column-shipmentGatewayShipment {
        span {
          @include width(100px);
          @include overflow(hidden);
          @include white-space(nowrap);
          @include text-overflow(ellipsis);
        }
      }
    }

    @include respond-to(tab-portrait) {
      @include overflow-y(scroll);
    }
  }
}

 .summaryPage-Preview-Window, .infoPage-Preview-Window {
  .print-page.Letter {
    @include width(856px);
  }

  .print-page.Legal {
    @include width(871px);
  }

  .print-page.A4 {
    @include width(856px);
  }

  .print-page.Letter, .print-page.Legal, .print-page.A4 {
    @include background($Shadow-Marble);
    @include overflow-x(hidden);
    @include overflow-y(auto);
    @include height(calc(100vh - 175px));
    @include padding-hor-ver(20px, 20px);

    .logo {
      @include height(40px);
    }

    .section-header {
      @include padding(16px, top);
      @include padding(10px, bottom);
    }

    .text-hyperlink {
      @include color($Link);
      @include text-decoration(underline);
    }

    .ibc-body-text-large {
      @include typography(14px, 600, normal, $Grey-Dark);
    }
  }

  .ibc-primary-default-button {
    .ibc-body-text-regular {
      @include color($Shadow-Marble);
    }
  }

  .preview-print-btn, .infoPage-preview-print-btn {
    @include float(right);
    @include margin-hor-ver(8px, 8px);
  }
}

@media print {
  .summaryPage-Preview-Window, .infoPage-Preview-Window {
    @include margin-all(0 !important, 0 !important, 0 !important, 0 !important);

    .print-page.Letter, .print-page.Legal, .print-page.A4 {
      @include overflow-y(unset !important);
      @include height(unset !important);
      @include position(absolute);
    }
  }
}
.legend-header {
  @include typography(12px, 500, normal, $Default-widget-accordion-text);
}

.mat-mdc-menu-item-text {
  @include display(flex);
  @include flex-align(center);
}

.cdk-overlay-transparent-backdrop {
  @include background(transparent);
}

.lookupSearch-icon.set-height {
  @include position(relative);
  @include position-bottom(3px);
}

.cdk-global-scrollblock {
  @include overflow-y(auto);
}

.lighter-bold {
  @include typography(14px,500, 1, $Grey-Dark)
}
