@import '../variable.scss';
@import '../mixins.scss';

.ibc-summary-table {
  @include box-shadow(0px 1px 6px 0px rgba(0, 0, 0, 0.16));
  @include borderRadius(6px !important);

  .mat-mdc-table {
    --mat-table-background-color: transparent;
  }

  .mat-mdc-row:nth-child(even) {
    @include background-color($Grey-Lightest);
  }

  .mat-mdc-row:nth-child(odd) {
    @include background-color($Shadow-Marble);
  }

  .mat-mdc-header-row {
    --mat-table-row-item-outline-color: #{$Grey-Slate};
    --mat-table-row-item-outline-width: 2px;
    --mat-table-header-container-height: 46px;

    .mat-sort-header-arrow {
      @include display(none);
    }
  }
}

.mat-mdc-row {
  --mat-table-row-item-container-height: 36px;
}

.mat-mdc-paginator {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-container-background-color: transparent;

  .mat-mdc-select-value {
    @include font-size(12px);
  }

  .mat-mdc-icon-button svg {
    --mdc-icon-button-icon-size: 28px;
  }
}

.mat-mdc-paginator-container {
  --mat-paginator-container-size: 36px;
  --mat-select-enabled-arrow-color: #{$primary-default};

  .mdc-notched-outline {
    @include height(32px);
  }

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    @include border-none();
    @include borderImp(bottom, 2px, solid, $primary-default);
    @include borderRadius(0 !important);
  }
}

.ibc-border-table {
  @include borderRadius(6px);
  @include borderVal(1px solid $Grey-Lighter !important);

  .mat-mdc-header-row {
    --mat-table-row-item-outline-color: #{$Grey-Shadow};
    --mat-table-row-item-outline-width: 2px;
    --mat-table-header-container-height: 46px;

    .mat-sort-header-arrow {
      @include display(none);
    }
  }
}

.ibc-print-table {
  @include border-all(1px, solid, $Grey-Shadow !important);
  @include border-collapse();
  @include display(block);
  @include overflow-x(hidden);

  thead, tbody {
    @include display(block);
    @include overflow-x(hidden);
  }

  tr {
    @include flex-gap-align(flex, 0, center);
    @include border-all(1px, solid, $Grey-Shadow !important);
    @include text-align(left);
    @include height(auto);
  }

  .table-text {
    @include margin-hor-ver(10px, 10px);
  }
}
