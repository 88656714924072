@import '../variable.scss';

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #{$Shadow-Marble};
  --mdc-checkbox-selected-focus-icon-color: #{$primary-default-dark};
  --mdc-checkbox-selected-hover-icon-color: #{$primary-default-dark};
  --mdc-checkbox-selected-icon-color: #{$primary-default-dark};
  --mdc-checkbox-selected-pressed-icon-color: #{$primary-default-dark};
  --mdc-checkbox-unselected-focus-icon-color: #{$primary-default-dark};
  --mdc-checkbox-unselected-hover-icon-color: #{$primary-default-dark};
  --mdc-checkbox-disabled-selected-icon-color: #{$Checkbox-Disabled-Active};
  --mdc-checkbox-disabled-unselected-icon-color: #{$Grey-Lighter};
  --mdc-checkbox-unselected-icon-color: #{$Grey-Lighter};
  --mdc-checkbox-unselected-pressed-icon-color: #{$Shadow-Marble};

  &.custom-checkbox{
    .mdc-label {
      @include typography(13px, 400, normal, $Default-Dark);
    }

    .mdc-checkbox__ripple {
      @include display(none);
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      @include background-color($Status-Success);
      @include border-color($Status-Success, $Status-Success, $Status-Success, $Status-Success)
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background{
      @include background-color($Status-Danger);
      @include border-color($Status-Danger, $Status-Danger, $Status-Danger, $Status-Danger);

      .mdc-checkbox__mixedmark:before {
        @include contentVal('close');
        @include font-family(Material Icons);
        @include color($Shadow-Marble);
        @include font-size(18px);
        @include flex-all(flex, 0, center, center)
      }

      .mdc-checkbox__mixedmark {
        @include display(block);
        @include border-color($transparent, $transparent, $transparent, $transparent);
      }
    }
  }

  &.roles-and-permission-custom-checkbox{
    .mdc-label {
      @include typography(13px, 400, normal, $Default-Dark);
    }

    .mdc-checkbox__ripple {
      @include display(none);
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      @include background-color($primary-default-dark);
      @include border-color($primary-default-dark, $primary-default-dark, $primary-default-dark, $primary-default-dark)
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate~.mdc-checkbox__background{
      @include background-color($Status-Danger);
      @include border-color($Status-Danger, $Status-Danger, $Status-Danger, $Status-Danger);

      .mdc-checkbox__mixedmark:before {
        @include contentVal('close');
        @include font-family(Material Icons);
        @include color($Shadow-Marble);
        @include font-size(18px);
        @include flex-all(flex, 0, center, center)
      }

      .mdc-checkbox__mixedmark {
        @include display(block);
        @include border-color($transparent, $transparent, $transparent, $transparent);
      }
    }

    .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate~.mdc-checkbox__background{
      @include background-color($Checkbox-Disabled-Active);
      @include border-color($Checkbox-Disabled-Active, $Checkbox-Disabled-Active, $Checkbox-Disabled-Active, $Checkbox-Disabled-Active);
    }
  }

  &.roles-and-permission-partial-checkbox {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate~.mdc-checkbox__background{
      @include background-color(white);

      .mdc-checkbox__mixedmark:before {
        @include contentVal('stop');
        @include font-family(Material Icons);
        @include color($primary-default-dark);
        @include font-size(20px);
        @include flex-all(flex, 0, center, center);
        @include position(relative);
        @include position-top(0);
      }

      .mdc-checkbox__mixedmark {
        @include display(block);
        @include border-color($transparent, $transparent, $transparent, $transparent);
      }
    }
    .mdc-checkbox .mdc-checkbox__native-control:disabled:indeterminate~.mdc-checkbox__background{
      @include border-color($Checkbox-Disabled-Active, $Checkbox-Disabled-Active, $Checkbox-Disabled-Active, $Checkbox-Disabled-Active);
      .mdc-checkbox__mixedmark:before {
        @include color($Checkbox-Disabled-Active);
      }
    }
  }

  &.basic-checkbox, &.checkbox-label {
    .mdc-label {
      @include typography(13px, 400, normal, $Default-Dark);
    }
  }
}

.mdc-checkbox__background {
  @include borderWidth(1px !important);
}

.miscellaneous-wrapper {
  .mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-unselected-icon-color: #{$primary-default};
  }
}
