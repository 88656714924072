@import '../mixins.scss';

.mat-mdc-card {
  @include borderRadius(10px !important);
}

.mat-mdc-card-header {
  @include padding-hor-ver-imp(16px, 12px);
  @include flex-align(center);
}

.mat-mdc-card-content {
  @include padding-hor-ver-imp(0, 20px);
}

.mat-mdc-card-content:last-child {
  @include padding-imp(20px, bottom);
}
